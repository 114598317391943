import React, { useEffect, useState } from 'react';
import TextPage from "../text-page";
import Api from '../../services';
import withHeaderAndFooter from '../with-header-and-footer-hoc';

const PaymentMethods = () => {

    const [content, setContent] = useState({});

    useEffect(() => {
        Api.text_page.getPaymentMethodTextContent()
            .then(response => setContent(response.data))
    }, []);



    return(
        <>
            <TextPage content={content} />
        </>
    );
}

export default withHeaderAndFooter(PaymentMethods);