export default {
    all: 'все',
    filter: {
        sale: 'Распродажа',
        priceHighLow: 'Цена(в убывающем порядке)',
        priceLowHigh: 'Цена(в возрастающем порядке)',
    },
    sortBy: 'Сортировать по',
    cart: 'Корзина',
    sendMessage: 'Отправить сообщение',
    name: 'имя',
    email: 'эл. почта',
    phone: 'номер телефона',
    favoriteSong: 'любимая песня',
    paymentAndDelivery: 'Оплата и Доставка',
    paymentMethods: 'Способы оплаты',
    privacyPolicy: 'Privacy Policy',
    allRightsReserved: 'Все права защищены',
    addToBag: 'Добавить в корзину',
    packageInclude: 'Пакет включает',
    free: 'Бесплатно',
    delivery: 'Доставка',
    similarProducts: 'Похожие продукты',
    item: 'продукт',
    size: 'Размер',
    quantity: 'Количество',
    price: 'Цена',
    subtotal: 'Промежуточный итог',
    discount: 'Скидка',
    total: 'Итог',
    goToPayment: 'перейти к оплате',
    wishlist: 'Отложить',
    personalDetails: 'Личные Данные',
    surname: 'фамилия',
    donate: 'пожертвовать',
    addNotes: 'Добавить заметки',
    byClicking: 'Нажав, вы принимаете наши',
    termsAndConditions: 'условия (ссылка на страницу)',
    receiveNews: 'Получать наши новости и акции',
    donateAndDownload: 'пожертвовать и скачать',
    getDownloadLink: 'получить ссылку дла скачивания',
    buyingFromBraind: 'Делать покупки на braind.am безопсно.',
    yourOrderWill: 'Ваш заказ будет обработан на наших защищенных серверах',
}