import React,{ useState, memo, useEffect } from "react";
import "./shop-grid-header.css";
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import blackheart from '../../../assets/favorite_gray_filled.svg';
import blackheartWithItems from "../../../assets/favorite_black_with_item.svg";
import bagcart from '../../../assets/cartbag.svg';
import Api from '../../../services';
import {useHistory} from "react-router-dom";
import {useStore} from "../../../context";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const ShopGridHeader = props => {
    const {categoryId ,setCategoryId, sort, setSort} = props;
    const [categories, setCategories] = useState([]);
    const history = useHistory();
    const { bag, wishlist, getLang, tr } = useStore();
    const labels = useSelector(store => store.labels);
    const wishlistHandler = () => {
        history.push(`${getLang()}/wishlist`);
    }

    const cartHeader = () => {
        history.push(`${getLang()}/cart`);
    }

    useEffect(() => {
        Api.products.getCategories()
            .then(data => setCategories(data?.data?.items))
    }, []);


    return(
        <div className="shop_grid_header">
            <div className="shop_grid_header_content">
                <div className="shop_header_select_container">
                    <FormControl>
                        <Select  value={sort} onChange={(event) => setSort(event.target.value)} displayEmpty>
                            <MenuItem value={JSON.stringify({})}>
                                <div className="shop_header_select_placeholder">
                                    <div className="select_icon"> </div>
                                    <div className="shop_header_select_label">
                                        {tr.sortBy}
                                    </div>
                                </div>
                            </MenuItem>
                            {labels?.items?.map(item =>
                                <MenuItem className="isopen-li" value={JSON.stringify({ label: Number(item.id) })}>
                                    <div className="shop_header_select_placeholder">
                                        <div className="select_icon"> </div>
                                        <div className="shop_header_select_label">
                                            {item.name}
                                        </div>
                                    </div>
                                </MenuItem>)}
                            <MenuItem className="isopen-li" value={JSON.stringify({ sale: 'desc' })}>
                                <div className="shop_header_select_placeholder">
                                    <div className="select_icon"> </div>
                                    <div className="shop_header_select_label">
                                        {tr.filter.sale}
                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem className="isopen-li" value={JSON.stringify({ price: 'desc' })}>
                                <div className="shop_header_select_placeholder">
                                    <div className="select_icon"> </div>
                                    <div className="shop_header_select_label">
                                        {tr.filter.priceHighLow}
                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem className="isopen-li" value={JSON.stringify({ price: 'asc' })}>
                                <div className="shop_header_select_placeholder">
                                    <div className="select_icon"> </div>
                                    <div className="shop_header_select_label">
                                        {tr.filter.priceLowHigh}
                                    </div>
                                </div>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="shop_header_nav_container">
                    <ul className="shop_header_nav">
                        <li onClick={()=> setCategoryId(0)}>
                            <a href="#" className={ categoryId === null || categoryId === 0 ? 'active_shop_link' : ''}><span>{tr.all}</span></a>
                        </li>
                        {categories.map((category) =>
                            <li onClick={()=>setCategoryId(category.id)}>
                                <a href="#" className={ categoryId === category.id ? 'active_shop_link' : ''}><span>{category.name}</span></a>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="shop_header_icons_container">
                    <div className="black_heart_container">
                        <img src={wishlist.length > 0 ? blackheartWithItems : blackheart} onClick={wishlistHandler}/>
                    </div>
                    <div className="shop_cart_container" onClick={cartHeader}>
                        <img src={bagcart}  className="cart_icon"/>
                        {tr.cart} ({bag.length})
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(ShopGridHeader);