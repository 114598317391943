export default {
    all: 'բոլոր',
    filter: {
        sale: 'զեղչ',
        priceHighLow: 'Գինը(բարձր - ցածր)',
        priceLowHigh: 'Գինը(ցածր - բարձր)',
    },
    sortBy: 'դասակարգել ըստ',
    cart: 'զամբյուղ',
    sendMessage: 'Ուղարկել նամակ',
    name: 'անուն',
    email: 'էլ հասցե',
    phone: 'հեռախոսահամար',
    favoriteSong: 'սիրելի երգ',
    paymentAndDelivery: 'Վճարում և Առաքում',
    paymentMethods: 'Վճարման եղանակներ',
    privacyPolicy: 'Գաղտնիության քաղաքականություն',
    allRightsReserved: 'Բոլոր իրավունքները պաշտպանված են',
    addToBag: 'Ավելացնել զամբյուղ',
    packageInclude: 'Փաթեթը ներառում է',
    free: 'Անվճար',
    delivery: 'Առաքում',
    similarProducts: 'Նման ապրանքներ',
    item: 'Ապրանքատեսակ',
    size: 'Չափս',
    quantity: 'Քանակ',
    price: 'Գին',
    subtotal: 'Գումարը',
    discount: 'Զեղչ',
    total: 'Ընդհանուր',
    goToPayment: 'Վճարել',
    wishlist: 'Ընտրվածներ',
    personalDetails: 'ԱՆձնական Տվյալներ',
    surname: 'ազգանուն',
    donate: 'նվիրաբերել',
    addNotes: 'Ավելացնել Նշումներ',
    byClicking: 'Սեղմելով Դուք ընդունում եք մեր',
    termsAndConditions: 'ընդհանուր դրույթներր և պայմանները (էջի հղումը)',
    receiveNews: 'Ստանալ մեր նորությունները և առաջարկները',
    donateAndDownload: 'նվիրաբերել և ներբեռնել',
    getDownloadLink: 'ստանալ ներբեռնման հղումը',
    buyingFromBraind: 'braind.am-ից գնելն ապահով է .',
    yourOrderWill: 'Ձեր պատվերը մշակվելու է մեր անվտանգ սերվերներում',
}