export default {
    all: 'all',
    filter: {
        sale: 'Sale',
        priceHighLow: 'Price(high - low)',
        priceLowHigh: 'Price(low - high)',
    },
    sortBy: 'sort by',
    cart: 'Cart',
    sendMessage: 'Send Message',
    name: 'name',
    email: 'email',
    phone: 'phone',
    favoriteSong: 'favorite song',
    paymentAndDelivery: 'Payment and Delivery',
    paymentMethods: 'Payment Methods',
    privacyPolicy: 'Privacy Policy',
    allRightsReserved: 'All rights reserved',
    addToBag: 'Add to bag',
    packageInclude: 'Package Include',
    free: 'Free',
    delivery: 'Delivery',
    similarProducts: 'Similar Products',
    item: 'Item',
    size: 'Size',
    quantity: 'Quantity',
    price: 'Price',
    subtotal: 'Subtotal',
    discount: 'Discount',
    total: 'Total',
    goToPayment: 'go to payment',
    wishlist: 'Wishlist',
    personalDetails: 'Personal Details',
    surname: 'surname',
    donate: 'donate',
    addNotes: 'Add Notes',
    byClicking: 'By clicking you accept our',
    termsAndConditions: 'terms and conditions (link to page)',
    receiveNews: 'Receive our news and promotions',
    donateAndDownload: 'donate and download',
    getDownloadLink: 'get download link',
    buyingFromBraind: 'Buying from braind.am is safe.',
    yourOrderWill: 'Your order will be processed on our secure servers',
}