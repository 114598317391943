import React, {memo, useEffect, useState} from "react";
import "./confirm-page.css";
import paymentlogo from "../../assets/paymentlogo.png";
import confirmblueicon from "../../assets/confirmblueicon.png";
import { useHistory } from "react-router-dom";
import {useStore} from "../../context";
import Api from "../../services";

const ConfirmPage = () => {
    const history = useHistory();
    const { getLang, clearBag } = useStore();
    const [content, setContent] = useState({});

    useEffect(() => {
        clearBag();
        Api.text_page.getSuccessPopupContent()
            .then(response => setContent(response?.data?.content))
    }, []);

    return(
        <div className="confirm_page">
            <div className="confirm_page_blue_side">

            </div>
            <div className="confirm_page_content">
                <div className="confirm_logo_container">
                    <img src={paymentlogo}/>
                </div>
                <div className="confirm_des_container">
                    <div className="confirm_icon">
                        <img src={confirmblueicon} />
                    </div>
                    <div className="confirm_des_title">
                        {content?.title}
                    </div>
                    <div className="confirm_des_des" dangerouslySetInnerHTML={{__html: content?.text}}>
                    </div>
                </div>
                <div className="confirm_button_container">
                    <div className="confirm_button" onClick={() => history.push(`${getLang()}/`)}>
                        go home
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(ConfirmPage);