import React, {memo, useState} from "react";
import "./footer.css";
import TextField from '@material-ui/core/TextField';
import {useSelector} from "react-redux";
import Api from '../../services';
import { Link } from "react-router-dom";
import {useStore} from "../../context";


const Footer = () => {
    const contacts = useSelector(store => store.contacts);
    const [nameInput, setNameInput] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [phoneInput, setPhoneInput] = useState('');
    const [songInput, setSongInput] = useState('');
    const [message, setMessage] = useState('');
    const [nameInputError, setNameInputError] = useState(false);
    const [emailInputError, setEmailInputError] = useState(false);
    const [mandatory, setMandatory] = useState(false);
    const [successSent, setSuccessSent] = useState(false);
    const { tr } = useStore();

    const validateEmail = email => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const sendMailHandler = () => {
        const nameError = !nameInput.length;
        const emailError = !emailInput.length || !validateEmail(emailInput);
        setNameInputError(nameError);
        setEmailInputError(emailError)

        if(!nameError && !emailError) {
            setMandatory(false);
            const form = new FormData();
            form.append('name', nameInput);
            form.append('phone', phoneInput);
            form.append('email', emailInput);
            form.append('song', songInput);
            form.append('message', message);

            Api.contact.postContact(form).then(response => {
                setNameInput('');
                setEmailInput('');
                setPhoneInput('');
                setSongInput('');
                setMessage('');
                setSuccessSent(true);
            }).catch(reason => console.log(reason.message));
        } else {
            setMandatory(true);
        }
    };
   

    return(
        <div className="footer">
            <div className="footer_content">
                <div className="footer_des_container">
                    <div className="footer_des_title">
                        {contacts?.content.title}
                    </div>
                    <div className="footer_des" dangerouslySetInnerHTML={{__html: contacts?.content.text}}>
                    </div>
                </div>
                <div className="footer_form_container">
                    <div className="footer_form_fields">
                        <form  noValidate autoComplete="off">
                            <TextField 
                            error ={nameInputError}
                            label={`* ${tr.name}`} value={nameInput} onChange={e =>  setNameInput(e.target.value)} />
                        </form>
                    </div>
                    <div className="footer_form_fields">
                        <form  noValidate autoComplete="off">
                            <TextField error={emailInputError} label={`* ${tr.email}`} value={emailInput} onChange={e => setEmailInput(e.target.value)} />
                        </form>
                    </div>
                    <div className="footer_form_fields">
                        <form  noValidate autoComplete="off">
                            <TextField label={tr.phone} value={phoneInput} onChange={e => setPhoneInput(e.target.value)}  />
                        </form>
                    </div>
                    <div className="footer_form_fields">
                        <form  noValidate autoComplete="off">
                            <TextField label={tr.favoriteSong} value={songInput} onChange={e => setSongInput(e.target.value)}  />
                        </form>
                    </div>
                    <div className="footer_form_textarea">
                        <textarea  name="" id="" cols="30" rows="4" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                    </div>
                    <div className="form_notifications">
                        {successSent && <p>Your message was sended</p>}
                        {mandatory && <p>Consider to fill all mandatory fields</p>}
                    </div>
                    <div className="footer_button_container">
                        <div className="page_button" onClick={sendMailHandler}>
                            {tr.sendMessage}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom_side">
                <div className="footer_address_container">
                    <p>{contacts?.content.address}</p>
                    <a href={`tel:${contacts?.content.phone}`}>{contacts?.content.phone}</a>
                    <p><a href={`mailto:${contacts?.content.email}`}>{contacts?.content.email}</a></p>

                </div>
                <div className="footer_bottom_links">
                     <Link className="ftr-links" to="/payment-delivery"> {tr.paymentAndDelivery} </Link>
                     <Link className="ftr-links" to="/payment-methods"> {tr.paymentMethods} </Link>
                     <Link className="ftr-links" to="/policy">{tr.privacyPolicy} </Link>
                </div>
            </div>
                <div className="footer_bottom_side footer_side_pdng">
                    <p>© 2011-2020 Braind LLC. {tr.allRightsReserved}</p>
                 </div>
        </div>
    );
}


export default memo(Footer);