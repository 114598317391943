import React, {useState, memo, useEffect, useRef} from "react";
import withHeaderAndFooter from "../../with-header-and-footer-hoc";
import ShopGridHeader from "../../shop-page/shop-grid-header";
import CollectionHeader from "../collection-header/collection-header";
import {CollectionsPartial, ProductsPartial} from "../../shop-page/shop-page-grid/_partials";
import { useParams } from 'react-router-dom';
import { ProductsBuilder } from '../../../builders';
import AOS from "aos";
import InfiniteScroll from "react-infinite-scroller";

const CollectionPageBody = ({ banners }) => {
    const{ id: pageId } = useParams();
    const [products, setProducts] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [categoryId, setCategoryId] = useState(null);
    const productsContainer = useRef(null);
    const [sort, setSort] = useState(JSON.stringify({}));

    useEffect(() => {
        setHasMore(true);
        AOS.init();
    }, []);

    const reloadItems = () => {
        setProducts([]);
        setPageNumber(1);
        setHasMore(true);
    }

    useEffect(() => {
        (categoryId !== null) && reloadItems();
    }, [categoryId]);

    useEffect(() => {
        if (products.length > 0) {
            reloadItems();
        }
    }, [sort])

    const loadMore = () => {
        setHasMore(false);
        const filter = JSON.parse(sort);
        const prod = new ProductsBuilder()
            .itemsCount(15)
            .page(pageNumber);

        console.log(banners);

        if (banners) {
            prod.setBanners([pageId])
        } else {
            prod.setCollections([pageId])
        }

        if (filter.new) {
            prod.orderByNewest(filter.new);
        }

        if (filter.price) {
            prod.orderByPrice(filter.price);
        }

        if (filter.sale) {
            prod.orderBySale(filter.sale);
        }

        if (filter.label) {
            prod.orderByLabel(filter.label);
        }

        if (Number(categoryId) > 0) {
            prod.setCategories([categoryId])
        }

        prod.get().then(response => {
            const item = response.data;
            setProducts(products => [...products, ...item.data]);
            setPageNumber(Number(item.current_page) + 1)
            if (Number(item.current_page) === Number(item.last_page)) {
                setHasMore(false)
            } else {
                setHasMore(true)
            }
        });
    }

    return(
        <>
            <CollectionHeader collectionId={pageId}/>
            <ShopGridHeader
                categoryId={categoryId}
                setCategoryId={setCategoryId}
                sort={sort}
                setSort={setSort}
            />
            <InfiniteScroll ref={productsContainer} className="shop_grid_container"
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                threshold={1250}>
                <div className='shop_grid_content'>
                    {products.map(item => <ProductsPartial item={item} />)}
                </div>
            </InfiniteScroll>
        </>
    );

}
export default memo(withHeaderAndFooter(CollectionPageBody));