import React, { useState, memo, useEffect } from "react";
import Api from '../../../../services';
import {useStore} from "../../../../context";
import {Link} from "react-router-dom";

const Banners = (props) => {
    const { data } = props;
    const { getLang } = useStore();

    return(
        <Link to={`banners/${data.id}`} className={'shop_grid_section_link'} >
            <div className="shop_grid_section_title" style={{backgroundImage: `url("${Api.imgUrl}/${data.banner}")`}}>
                {data.name}
            </div>
        </Link>
    );

}

export default memo(Banners);