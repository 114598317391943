const developmentUrl = {
  apiBaseUrl: "https://braind-admin.nextstack.org",
  imagesBaseUrl: "https://braind-admin.nextstack.org/storage/",
  defaultLanguage: "en"
}
const productionUrl = {
  apiBaseUrl: "https://shop-content.braind.am",
  imagesBaseUrl: "https://shop-content.braind.am/storage/",
  defaultLanguage: "en"
}
export default process.env.NODE_ENV === 'development' ? developmentUrl : productionUrl