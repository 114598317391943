import React, {Component} from "react";
import withHeaderAndFooter from "../../with-header-and-footer-hoc";
import "./cart-page-body.css";
import CartHeader from "../cart-header";
import CartContent from "../cart-content";
import { useSelector, useDispatch } from "react-redux";

const CartPageBody = () => {
    const bag = useSelector(store => store.bag);
    const dispatch = useDispatch();

    return(
        <div className="cart_page_container">
            <CartHeader/>
            <CartContent/>
        </div>
    );
}
export default withHeaderAndFooter(CartPageBody);