import React, {memo, useState} from "react";
import "./navigation-bar.css";
import logo from "../../assets/logo.png";
import {HamburgerButton} from "react-hamburger-button";
import logo2 from "../../assets/logo2.png";
import {useSelector} from "react-redux";
import Api from '../../services';
import { Link } from "react-router-dom";
import {useStore} from "../../context";

const NavigationBar = () => {
    const contacts = useSelector(store => store.contacts);
    const navigation = useSelector(store => store.navigation);
    const [open, setOpen] = useState(false);
    const { getLang } = useStore();

    const langChange = lang => {
        let oldUrl = window.location.href.split('/');
        const links = oldUrl[3].split('-');
        const locales = ['hy', 'ru', 'en'];

        switch (true) {
            case locales.includes(links[0]) && lang === 'en':
                oldUrl = oldUrl.filter( (item, index) => index !== 3);
                break;
            case locales.includes(links[0]):
                links[0] = lang
                oldUrl[3] = links.join('/');
                break;
            case lang !== 'en':
                links.unshift(lang);
                oldUrl[3] = links.join('/');
        }

        const newUrl = oldUrl.join('/');
        window.location.replace(newUrl)
    }

    const handleClick = () => {
        setOpen(val => !val);
    }

    const navGenerator = () => {
        let second = 1;
        const data = [];

        navigation.items.forEach( item => {
            second += 0.1;
            data.push(<li style={{transitionDelay: `${second}s`}}><a href={item.url}>{item.name}</a></li>);

            if (item.children.length > 0) {
                item.children.forEach((item) => {
                    second += 0.1;
                    data.push(<li style={{transitionDelay: `${second}s`}} className="nav_service_pages"><a href={item.url}>{item.name}</a></li>);
                })
            }
        });

        return data;
    }


    return(
        <>
            <div className="navigation_bar">
                <Link  to={`${getLang()}/`}>
                <div className="nav_logo">

                </div>
                <div className="nav_mobile_logo">

                </div>
                </Link>
                <div className="hamburger_menu">
                    <HamburgerButton
                        open={open}
                        onClick={handleClick}
                        width={30}
                        height={16}
                        strokeWidth={4}
                        color='black'
                        animationDuration={0.5}
                    />
                </div>
                <div className="nav_socials">
                    {contacts?.social_links.map(item =>
                        <a href={item.url} className="face nav-social" target="_blank">
                            <img width="15" src={`${Api.imgUrl}/${item.image}`} />
                        </a>)}
                </div>
            </div>
            <div className={open ? 'nav_content nav_active' : 'nav_content '}>
                <div className="nav_header">
                    <div className="nav_header_logo">
                        <img src={logo2}/>
                    </div>
                    <div className="nav_lang_container">
                        <div className={`nav_lang_item ${localStorage.getItem("lang") === "en" ? "active-bold" : ""}`}onClick={()=>{langChange('en')}}>
                            Eng
                        </div>
                        <div className={`nav_lang_item ${localStorage.getItem("lang") === "hy" ? "active-bold" : ""}`} onClick={()=>{langChange('hy')}}>
                            Arm
                        </div>
                        <div className={`nav_lang_item ${localStorage.getItem("lang") === "ru" ? "active-bold" : ""}`} onClick={()=>{langChange('ru')}}>
                            Rus
                        </div>
                    </div>
                </div>
                <div className="nav_pages_list_container">
                    <ul className={open ? 'nav_menu nav_nav_menu_active' : 'nav_menu nav_nav_menu_unactive'}>
                        {navigation && navGenerator()}

                        {/*<li style={{transitionDelay: '1.2s'}}><a href="#">Portfolio</a></li>
                        <li style={{transitionDelay: '1.3s'}}><a href="#">Services</a></li>
                        <li style={{transitionDelay: '1.4s'}} className="nav_service_pages"><a href="#">Logo Design</a></li>
                        <li style={{transitionDelay: '1.5s'}} className="nav_service_pages"><a href="#">Branding</a></li>
                        <li style={{transitionDelay: '1.6s'}} className="nav_service_pages"><a href="#">BrandBook</a></li>
                        <li style={{transitionDelay: '1.7s'}} className="nav_service_pages"><a href="#">Naming</a></li>
                        <li style={{transitionDelay: '1.8s'}} className="nav_service_pages"><a href="#">Ui/Ux design</a></li>
                        <li style={{transitionDelay: '1.9s'}}><a href="#">Contacts</a></li>
                        <li style={{transitionDelay: '2s'}}><a href="#">Shop</a></li>
                        <li style={{transitionDelay: '2.1s'}}><a href="#">Black Friday</a></li>*/}
                    </ul>
                </div>
                <div className="nav_phone_number">
                <li><a href="tel:(+374)12 900 555">(+374)12 900 555</a> </li>
                </div>
            </div>
        </>
    );
}

export default memo(NavigationBar);