import React, {Component} from "react";
import withHeaderAndFooter from "../../with-header-and-footer-hoc";
import CartHeader from "../../cart-page/cart-header";
import WishlistContent from "../wishlist-content";

class WishListPageBody  extends  Component{

    render() {
        return(
            <div className="wish_list_page">
                <CartHeader/>
                <WishlistContent/>
            </div>
        );
    }
}

export default withHeaderAndFooter(WishListPageBody);