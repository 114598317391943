import Api from "../../../../services";
import xbutton from "../../../../assets/xbutton.png";
import React, { memo, useEffect, useState } from "react";
import minus from '../../../../assets/minus.svg';
import plus from '../../../../assets/plus.svg';
import { useStore } from "../../../../context";
import blackdram from "../../../../assets/black-price.svg";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Link } from "react-router-dom";

const MobileItem = props => {
    const { item } = props;
    const [productCount, setProductCount] = useState(Number(item.quantity));
    const { removeFromBag, updateBag, updateProductInBag, tr } = useStore();

    const changeSizeHandler = e => {
        const newItemId = e.target.value;
        updateProductInBag(item.id, newItemId)
    }

    const productCounterHandler = (param) => {
        switch (param) {
            case '-':
                setProductCount(count => count > 1 ? --count : count)
                break;
            case '+':
                setProductCount(count => ++count)
                break;
        }

        updateBag(item.id, productCount);
    };
    return (
        <div className="card_mobile_item">
            <div className="cart_mobile_item_x_container" onClick={() => removeFromBag(Number(item.id))}>
                <img src={xbutton} />
            </div>
            <div className="card_mobile_item_content">
                <Link class='link-brd' to={`product/${item.slug}`}>
                    <div className="cart_mobile_item_image">
                        {item.images && <img src={`${Api.imgUrl}${item.images?.[0]?.image}`} />}
                    </div>
                </Link>
                <div className="cart_mobile_des_container">
                    <Link class='link-brd' to={`product/${item.slug}`}>
                        <div className="cart_mobile_des_title">
                            {item.title}
                        </div>
                    </Link>
                    <div className="cart_mobile_des_size">
                        {item.sizes.length > 0 && <div className="cart_mobile_des_quality cart_item_quality">
                            <FormControl>

                                <Select
                                    native
                                    value={Number(item.id)}
                                    onChange={changeSizeHandler}
                                >
                                    {item.sizes.map(sizeItem => <option value={Number(sizeItem.stock_id)}>{sizeItem.size.size}</option>)}
                                </Select>
                            </FormControl>
                        </div>}
                    </div>
                    {item?.allow_quantity &&
                        <div className="product_count_container wishlist-inp">
                            <div className="product_count_button" onClick={() => productCounterHandler('-')}>
                                <img src={minus} />
                            </div>
                            <input type="text" readOnly className="product_count_input" value={productCount} />
                            <div className="product_count_button" onClick={() => productCounterHandler('+')}>
                                <img src={plus} />
                            </div>
                        </div>}
                    <div className="cart_mobile_des_price">
                        {item?.cost > 0 ? item.cost.toLocaleString('de-DE') : tr.free} {item?.cost > 0 && <img src={blackdram} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(MobileItem);