import React,{memo, useEffect} from "react";
import "./similar-products.css";
import {Link} from "react-router-dom";
import {useStore} from "../../../../context";
import greyprice from "../../../../assets/greyprice.svg";
import dram from "../../../../assets/amd.svg";
import redprice from "../../../../assets/reddram.svg";
import heart from '../../../../assets/heart.svg'
import selectedHeart from '../../../../assets/heart_selected.svg';
const emptyProduct = {
    images: [],
    active: true,
    cost: 0,
    description: "",
    discount: 0,
    download_link: null,
    id: 0,
    label: null,
    label_id: null,
    out_of_stock: false,
    price: 0,
    product_id: 0,
    size: null,
    size_id: null,
    slug: "",
    title: "",
};


const SimilarProducts = props => {
    const {products, setProduct, imgUrl} = props;
    const { getLang } = useStore();
    const { addToWishlist, wishlist, removeFromWishlist, tr } = useStore();


    return(
        <div  className="similar_products_section">
            <div className="similar_products_title">
                { tr.similarProducts }
            </div>
            <div className="similar_products_grid_container">
                <div className="similar_products_grid_content">
                    {products.items?.map(product =>
                        <div className="similar_product_item">
                            <div className="similar_product_item_content">
                                <div  className="fav_cnt">
                                    <img  onClick={()=>{wishlist.includes(product.id) 
                                            ? removeFromWishlist(product.id) 
                                            : addToWishlist(product.id) }}
                                    src={wishlist.includes(product.id) ? selectedHeart : heart} className="fav-icn-details" />
                                </div>
                                {product.images &&
                                    <Link onClick={()=>setProduct(emptyProduct)} to={`${getLang()}/product/${product.slug}`} >
                                        <div className="similar_product_image">
                                            <img src={`${imgUrl}${product.images[0]?.image}`}/>
                                        </div>
                                    </Link>}
                                    <Link className="similiar_link" onClick={()=>setProduct(emptyProduct)} to={`${getLang()}/product/${product.slug}`} >
                                        <div className="similar_cnt">
                                            <div className="similar_product_title">
                                                {product?.title}
                                            </div>
                                            <div className="similar_products">
                                            <span className="product-discount">{ product.discount.toLocaleString('de-DE') > 0 && <span className="line-rel">{product.price}<div className="line"></div><img src={greyprice} /></span>}</span>
                                            <span className={product.discount.toLocaleString('de-DE') > 0 ? "price"  : null} >
                                                {product.cost.toLocaleString('de-DE') > 0 ? `${product.cost.toLocaleString('de-DE')}` : tr.free} {product.cost.toLocaleString('de-DE') > 0 && <img src={product.discount.toLocaleString('de-DE') > 0 ? redprice : dram} /> }
                                            </span>
                                            </div>
                                        </div>
                                        </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default memo(SimilarProducts);