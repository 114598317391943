import React, { useEffect, useState, memo } from "react";
import './cart-content.css';
import { makeStyles } from "@material-ui/core/styles";
import card1 from "../../../assets/card1.svg";
import card2 from "../../../assets/card2.svg";
import card from "../../../assets/card.svg";
import { useStore } from "../../../context";
import Api from '../../../services';
import DesktopItem from "./_partials/desktop-item";
import MobileItem from "./_partials/mobile-item";
import { Link } from "react-router-dom";
import blackdram from "../../../assets/black-price.svg";
import dram from "../../../assets/amd.svg";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const CartContent = () => {
    const { bag, syncBag, tr } = useStore();
    const [cartData, setCartData] = useState(null);


    useEffect(() => {
        const newData = [...bag];
        const requestData = newData.map(item => {
            return {
                stock_id: item.id,
                quantity: item.quantity
            };
        });

        Api.cart.getCart({
            items: requestData || []
        }).then(response => {
            const data = response?.data || [];

            const ids = data?.items?.map(item => item.id);
            syncBag(ids);
            setCartData(data);
        })
    }, [bag])


    return (
        <div className="cart_content_container">
            <div className="cart_content">
                <div className="cart_content_title">
                    {tr.cart} <div>({bag?.length} items)</div>
                </div>
                <div className="cart_grid_main_container">
                    <table cellPadding="0" cellSpacing="0" className="cart_desktop_table">
                        <tr>
                            <th>
                                {tr.item}
                            </th>
                            <th>
                                {tr.size}
                            </th>
                            <th>
                                {tr.quantity}
                            </th>
                            <th>
                                {tr.price}
                            </th>
                            <th> </th>
                        </tr>
                        {cartData?.items?.length > 0 && cartData?.items?.map(item =>
                            <DesktopItem item={item} />
                        )}
                    </table>
                    <div className="card_grid_mobile_container">
                        {cartData?.items?.length > 0 && cartData?.items?.map(item =>
                            <MobileItem item={item} />
                        )}
                    </div>
                    <div className="cart_pricing_container">
                        <div className="cart_pricing_content">
                            <div className="cart_pricing_row">
                                <div className="cart_pricing_title">
                                    {tr.subtotal}
                                </div>
                                <div className="cart_pricing_value">
                                    {cartData?.calculate?.sub_total > 0 ? cartData.calculate.sub_total : tr.free}
                                    {cartData?.calculate?.sub_total > 0 && <img src={blackdram} />}
                                </div>
                            </div>
                            {cartData?.calculate?.total > 0 &&
                                <div className="cart_pricing_row">
                                    <div className="cart_pricing_title">
                                        {tr.delivery}
                                    </div>
                                    <div className="cart_pricing_value cart_price_blue_value">
                                        {tr.free}
                                    </div>
                                </div>}
                            <div className="cart_pricing_row">
                                <div className="cart_pricing_title">
                                    {tr.discount}
                                </div>
                                <div className="cart_pricing_value">
                                    {cartData?.calculate?.discount > 0 ? cartData.calculate.discount : "0"}
                                    {cartData?.calculate?.discount > 0 && <img src={blackdram} />}
                                </div>
                            </div>
                        </div>
                        <div className="cart_pricing_total_price_container">
                            <div className="cart_pricing_content">
                                <div className="cart_pricing_row">
                                    <div className="cart_pricing_title title_bld">
                                        {tr.total}
                                    </div>
                                    <div className="cart_pricing_value cart_price_blue_value">
                                        {cartData?.calculate?.total > 0 ? cartData.calculate.total : tr.free}
                                        {cartData?.calculate?.total > 0 && <img src={dram} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bank_cards_section">
                    <div className="bank_cards">
                        <div className="bank_card">
                            <img src={card} />
                        </div>
                        <div className="bank_card">
                            <img src={card2} />
                        </div>
                        <div className="bank_card">
                            <img src={card1} />
                        </div>
                    </div>
                    <Link className="cart_page_button" to={"/payment"}>
                        <span>
                            {tr.goToPayment}
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default memo(CartContent);