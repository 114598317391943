import React, { useEffect } from "react";
import "./app.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ShopPageBody from "../shop-page/shop-page-body/shop-page-body";
import ProductDetailsPageBody from "../product/product-details/product-details-page-body/product-details-page-body";
import CartPageBody from "../cart-page/cart-page-body/cart-page-body";
import PaymentPageBody from "../payment-page/payment-page-body/payment-page-body";
import WishListPageBody from "../wishlist/wishlist-page-body/wishlist-page-body";
import CollectionPageBody from "../collection-page/collection-page-body/collection-page-body";
import ConfirmPage from "../confirm-page/confirm-page";
import PaymentDelivery from "../delivery";
import Api from '../../services';
import { useDispatch } from 'react-redux'
import PaymentMethods from "../payment-methods/methods";
import PrivacyPolicy from "../Privacy-Police/privacy-policy";

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    Api.products.getCollections()
      .then(data => dispatch({
        type: 'COLLECTIONS',
        data: data.data.items
      }))
  }, []);

  useEffect(() => {
    Api.products.getBanners()
      .then(data => dispatch({
        type: 'BANNERS',
        data: data.data.items
      }))
  }, []);

  useEffect(() => {
    Api.contact.getContacts()
        .then(data => dispatch({
          type: 'CONTACTS',
          data: data.data,
        }))
  }, []);

    useEffect(() => {
        Api.navigation.getNavigation()
            .then(data => dispatch({
                type: 'NAVIGATION',
                data: data.data,
            }))
    }, []);

    useEffect(() => {
        Api.products.getLabels()
            .then(data => dispatch({
                type: 'LABELS',
                data: data.data,
            }))
    }, []);

  const link = window.location.pathname.split('/')[1]
  const langs = ['en', 'ru', 'hy'];
  const locale = langs.includes(link) ? link : 'en';

  const base = '/:locale(en|ru|hy)?';
    return (
    <div className={`App ${locale}`}>
      <Router>
        <Switch>
          <Route path={`${base}/`} exact component={ShopPageBody} />
          <Route path={`${base}/product/:slug`} exact component={ProductDetailsPageBody} />
          <Route path={`${base}/cart`} exact component={CartPageBody} />
          <Route path={`${base}/payment`} exact component={PaymentPageBody} />
          <Route path={`${base}/wishlist`} exact component={WishListPageBody} />
          <Route path={`${base}/collection/:id`} exact component={()=><CollectionPageBody />} />
          <Route path={`${base}/banners/:id`} exact component={()=><CollectionPageBody banners />} />
          <Route path={`${base}/confirm`} exact component={ConfirmPage} />
          <Route path={`${base}/payment-delivery`} exact component={PaymentDelivery} />
          <Route path={`${base}/payment-methods`} exact component={PaymentMethods} />
          <Route path={`${base}/policy`} exact component={PrivacyPolicy} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
