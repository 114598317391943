import React, {useState} from "react";
import withHeaderAndFooter from "../../with-header-and-footer-hoc";
import "./shop-page-container.css";
import ShopPageSlider from "../shop-header-slider";
import ShopPageGrid from "../shop-page-grid";
import ShopGridHeader from "../shop-grid-header";

const ShopPageBody = () => {

    const [categoryId, setCategoryId] = useState(null);
    const [sort, setSort] = useState(JSON.stringify({}));

    return(
        <div className="shop_page_container">
            <ShopPageSlider/>
            <ShopGridHeader
                categoryId={categoryId}
                setCategoryId={setCategoryId}
                sort={sort}
                setSort={setSort}
            />
            <ShopPageGrid
                categoryId={categoryId}
                sort={sort}
            />
        </div>
    );
}
export default withHeaderAndFooter(ShopPageBody);