import React, { useState, memo, useEffect } from "react";
import Api from '../../../../services';
import {Link} from "react-router-dom";
import heart from '../../../../assets/heart.svg'
import selectedHeart from '../../../../assets/heart_selected.svg';
import {useStore} from "../../../../context";
import dram from "../../../../assets/amd.svg";
import greyprice from "../../../../assets/greyprice.svg";
import redprice from "../../../../assets/reddram.svg";



const Products = (props) => {
    const { addToWishlist, wishlist, removeFromWishlist, tr, getLang } = useStore();
    const item = props.item;

    return(
        <div data-aos-duration="1000" data-aos-delay="100" data-aos="fade-up" className="shop_grid_item">
            <div className="shop_grid_item_content">
                   <div>
                       <img onClick={()=>{wishlist.includes(item.id) ? removeFromWishlist(item.id) : addToWishlist(item.id) }}
                            src={wishlist.includes(item.id) ? selectedHeart : heart} className="fav-icn" />
                   </div>
                    <div className="labels-container">
                    {item.out_of_stock && <div className="cart_out cart-stl">Out of stock</div>}
                    {item.discount > 0 && <div className="cart_sale cart-stl">{`Sale -${item.discount}%`}</div>}
                    {item.label && <div style={{backgroundColor: item.label.hex}} className=" cart-stl">{item.label.name}</div>}
                    </div>
                    <Link to={`/${getLang() ? getLang().concat('/') : ''}product/${item.slug}`}>
                        <div className="shop_grid_item_image">
                            {item.images?.map((item, index)=>{
                                if (index === 0) {
                                    return <img src={`${Api.imgUrl}${item.image}`} />
                                }
                                return <img src={`${Api.imgUrl}${item.image}`} style={{zIndex:1,animationDelay: `${index}s`,animationDuration:"4s"}} />
                            })}
                        </div>
                    </Link>
                    <Link  className="product_link" to={`/${getLang() ? getLang().concat('/') : ''}product/${item.slug}`}>
                        <div className="shop_item_flex">
                                <div className="shop_item_title">
                                    {item.title}
                                </div>
                                <div className="shop_item_price">
                                <span className="item-discount">{ item.discount > 0 && <span className="line-rel">{item.price.toLocaleString('de-DE')}<div className="line"></div><img src={greyprice} /></span>}</span>
                                <span className={item.discount > 0 ? "price"  : null} >
                                    {item.cost > 0 ? `${item.cost.toLocaleString('de-DE')}` : tr.free} {item.cost > 0 && <img src={item.discount > 0 ? redprice : dram} /> }
                                </span>
                                </div>
                            </div>
                  </Link>
                </div>
            </div>
    );

}

export default memo(Products);