import React,{memo} from "react";
import blackheart from '../../../assets/favorite_gray_filled.svg';
import "./cart-header.css";
import blackheartWithItems from '../../../assets/favorite_black_with_item.svg';
import cartbag from '../../../assets/cartbag.svg';
import {useHistory, Link} from "react-router-dom";
import { useStore } from '../../../context';


const CartHeader = () => {
    const history = useHistory();
    const { bag, wishlist, getLang, tr } = useStore();

    const wishlistHandler = () => {
        history.push(`${getLang()}/wishlist`);
    }

    const cartHeader = () => {
        history.push(`${getLang()}/cart`);
    }


    return(
        <div className="cart_header">
                <Link className="braind-link"  to={`${getLang()}/`}>
            <div className="cart_header_title">
                Braind Shop
            </div>
            </Link>
            <div className="cart_header_cart_container">
                <img src={wishlist.length > 0 ? blackheartWithItems : blackheart}  className="black_heart" onClick={wishlistHandler}/>
                <div className="cart_header_container" onClick={cartHeader}>
                    <img src={cartbag}/>
                    <div className="cart_label" >
                        {tr.cart} ({bag.length})
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(CartHeader);