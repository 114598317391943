import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import Redux from './store';
import Context from './context';
import { BrowserRouter } from 'react-router-dom';


const link = window.location.pathname.split('/')[1]
const langs = ['en', 'ru', 'hy'];
const locale = langs.includes(link) ? link : 'en';
localStorage.setItem('lang', locale);

ReactDOM.render(
    <BrowserRouter basename={`/${locale}`}>
      <Redux>
          <Context>
              <App />
          </Context>
      </Redux>
    </BrowserRouter>
  , document.getElementById('root'));

serviceWorker.unregister();
